import classNames from 'classnames'
import { Image } from '~/components/Image'
import { SectionContainer } from '~/components/SectionContainer'
import { getImageSrcSet } from '~/helpers'
import ImageOverlayLinks from '../../components/ImageOverlayLinks/ImageOverlayLinks'
import styles from './FullWidthImage.module.css'
import { FullWidthImageProps } from './FullWidthImage.types'

export const FullWidthImage = ({
  desktopImage,
  mobileImage,
  tabletImage,
  width = 'narrow',
}: FullWidthImageProps) => {
  const mobileImg = mobileImage || tabletImage || desktopImage
  const tabletImg = tabletImage || desktopImage || mobileImage
  const desktopImg = desktopImage || tabletImage || mobileImage

  return (
    <SectionContainer
      width={width}
      wrapperClassName='rounded-md overflow-hidden'>
      <div className={classNames('relative', styles.imageWrapper)}>
        {desktopImage && (
          <>
            <ImageOverlayLinks
              desktopImage={desktopImage}
              tabletImage={tabletImage}
              mobileImage={mobileImage}
            />
            <Image
              src={desktopImg.src}
              addSrcSet={false}
              sources={
                <>
                  <source
                    srcSet={getImageSrcSet(mobileImg.src, [
                      { intrinsicImageSize: '700', width: '700' },
                    ])}
                    media='(max-width: 768px)'
                  />
                  <source
                    srcSet={getImageSrcSet(tabletImg.src, [
                      { intrinsicImageSize: '1000', width: '1000' },
                    ])}
                    media='(max-width: 1024px)'
                  />
                </>
              }
              alt={desktopImg.altText}
              width={desktopImage.width}
              height={desktopImage.height}
            />
          </>
        )}
      </div>
    </SectionContainer>
  )
}
